// Script that controls the language dropdown-menu inside the header
document.addEventListener("turbolinks:load", function() { 
  const languageMenu = document.querySelector('.change-language-container')
  const openLanguageMenu = document.querySelector('.language-menu')

  languageMenu.addEventListener("click", () => {
    openLanguageMenu.classList.toggle("language-menu-open")
  });

});
