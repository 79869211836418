//explanation of why everything is wrapped inside an event listener
//TurboLinks gem makes your application behave like a single 
//page javascript application. For this reason, javascript would
//not reload when switching page, and all the visual elements
//that relied on javascript would stop working.
//This event listener reloads the javascript it wraps evereytime
//TurboLinks gem "takes an action", so your javascript would
//reload when switching page
document.addEventListener("turbolinks:load", function() {

  const body = document.body

  const menu = document.getElementById("menu")
  menu.addEventListener("click", () => {
    openNav();
  });
  const close = document.getElementById("closebtn")
  close.addEventListener("click", () => {
    closeNav();
  });

  //clicking the overlay (which is all the area outside the
  //sidenav) will close the sidenav.
  const overlay = document.getElementById("sidenav-overlay")
  overlay.addEventListener("click", () => {
    closeNav();
  });


  /* Set the width of the side navigation to 250px */
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    // overlay.style.width = '100%';
    // overlay.style.height = '100%';
    overlay.style.visibility = 'visible';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    body.classList.add('noscroll');
  };

  /* Set the width of the side navigation to 0 */
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    // overlay.style.width = '0';
    // overlay.style.height = '0';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    overlay.style.visibility = 'hidden';
    body.classList.remove('noscroll');
  };

});
