// this file is the scripts that controls the dropdown menus inside the home page on the mobile version
document.addEventListener("turbolinks:load", function() {

  const toggle = document.querySelectorAll('.dropdown')
  

  //the dropdown is a separate node that stays inside the same container of the title, but has a height set to 0. This script puts an eventlistener on the title and opens the dropdown that is a child element of the container where the title is .
  toggle.forEach(function(dropdown) {
    var button = dropdown.querySelector('.dropdown-button')
    button.addEventListener("click", () => {
      button.classList.toggle("dropdown-open")
      var openContent = dropdown.querySelector('.dropdown-content')
      openContent.classList.toggle("dropdown-content-open")
      })
    })
  })

  // toggle.forEach(function(dropdown) {
  //   dropdown.addEventListener("click", () => {
  //     open.forEach(function(dropdownContent) {
  //       dropdownContent.classList.toggle("dropdown-open")
  //     })
  //   })
  // })

  // const toggle = document.querySelector('.dropdown')
  // toggle.addEventListener("click", () => {
  //   toggle.classList.toggle("dropdown-open")
  // })
  //});
